/* Landing screen component and navigation ba component styling*/
.header-color{

  background: #c31432;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #240b36, #c31432);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #240b36, #c31432); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
/* This is my main gradient navbar ^^^ */
}
.landing-grid{

  background: #c31432;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #240b36, #c31432);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #240b36, #c31432); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  /* This is my main gradient landing page ^^^ */
  text-align: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  }

  .avatar-img {
    width: 21.5rem;
    border-radius: 15%;
    border: 0.25rem solid black;
  }

  .avatar-div{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  
  .banner-text{
    background-color: black;
    opacity: .7;
    width: 40rem;
    margin: auto;
    border-radius: 10%;
  }

  .banner-text h1{
    font-size: 4rem;
    font-weight: bold;
    color: white; 
    
  }

  .banner-text hr{
    border-top: 0.5rem dotted white;
    width: 15rem;
    margin: auto;

  }

  .banner-text p{
    color: white;
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 1rem;
  }

  .social {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: auto;
  }
  
  .social i {
    color: white;
    font-size: 5em;
    padding: 8px;
  }

/*Project screen component styling*/
.projects-grid {
  display: inline-block;
  padding: 4rem;
}

.whole-grid{
  text-align: center;
}

.buttonHover:hover {
  transform: scale(1.05);
}

.cardHover:hover {
  background-color: #F3F0F0;
  transform: scale(1.1);
}

#project-heading{
  Font-family: Monaco;
  text-align: center;
}

/*contact page*/
.contact-body {
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 0.1rem;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width:80%;
  height:60rem;
  background: white;
  color: black; 
  margin-bottom: 10rem;
  
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;

}
.contact-grid p {
  font-family: 'oxygen', sans-serif;
  font-weight: bold;

}
.contact-grid hr {
  margin: auto;
  width: 50%;
  border-top: 0.5rem dotted black;

}

.contact-list i{
  font-size: 5rem;
  padding-right: 2rem;
  padding-left: 2rem;
  
}

.contact-info{
  font-size: 3rem; font-family: 'Anton'; 
}

.message-box{
  padding-top: 2rem;
}

/*About page component styling*/
#aboutDescription {
max-width: 70%;
margin: auto;
padding-bottom: 5rem;
}

#about-heading {
  font-family: Monaco;
  text-align: center;
}

.demo-big-content{
  overflow: auto;
}

@media only screen and (max-width: 650px) {

  /* Switch gradient in small screen to bring the focus to Drawer*/
  .header-color{

    background: #c31432;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #240b36, #c31432);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #240b36, #c31432); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
  /* This is my main gradient navbar ^^^ */
  }
  .landing-grid{
  
    background: #c31432;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #240b36, #c31432);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #240b36, #c31432); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  }
   /* Media for resposivness in Contact component*/

  .contact-grid{
    margin-bottom: 2rem;
  }

  .contact-list{
    display: inline-block;
    overflow: auto;
  }

  .contact-list i{
    font-size: 3.5rem;
    padding-right: 1.25rem;
    
  }
  .contact-info{
    font-size: 1.75rem;
    font-family: 'Anton';
    display: inline-block;
  }

  .contact-heading{
    font-size: 2rem;
  }


  /* Media responsivness for the landing page*/
  .avatar-img {
    width: 15rem;
    border-radius: 15%;
    border: 0.25rem solid black;
  }
  .avatar-div{
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  
  .banner-text{
    background-color: black;
    opacity: .7;
    width: 20rem;
    margin: auto;
    border-radius: 10%;
  }

  .banner-text h1{
    font-size: 2rem;
    font-weight: bold;
    color: white; 
    
  }

  .banner-text hr{
    border-top: 0.5rem dotted white;
    width: 10rem;
    margin: auto;

  }

  .banner-text p{
    color: white;
    font-size: 1rem;
    padding: 0.5rem;
  }
  
  .social i {
    color: white;
    font-size: 3em;
    padding: 4px;
  }

  /*Media responsiveness for the projects component*/
  .projects-grid {
    float: right;
    padding: 0.5rem;
  }

  }